.skeleton {
  width: 100%;
  background: #ddd;
  margin: 10px 0;
}

.skeleton.table {
  height: 40px;
  width: 100%;
  background: #dddd;
}

.skeleton.text {
  width: 100%;
  height: 52px;
}

.skeleton.title {
  width: 50%;
  height: 20px;
  margin-bottom: 15px;
}

.skeleton.pagination {
  width: 20%;
  height: 25px;
  background-color: #ddd;
}

.skeleton.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding-bottom: 1rem;
}

.skeleton.username {
  width: 100%;
  height: 8px;
  background: #eee;
}

.skeleton.skeleton.username2 {
  width: 100%;
  height: 8px;
  background: #eee;
}

.skeleton.thumbnail {
  height: 100px;
  width: 100px;
}

.skeleton-wrapper {
  margin: 20px auto;
  padding: 10px 15px;
  position: relative;
}

/*Themes*/

.skeleton-wrapper.light {
  background-color: #f2f2f2f2;
}

.skeleton-wrapper.avatar {
  background-color: transparent;
}

.skeleton-wrapper.dark {
  background-color: #f3f1f1;
}

.skeleton-wrapper.light .skeleton {
  background-color: #e8e9e3;
}

/* Animation Effect */
.animation-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  content: 'loading';
}

.animation {
  width: 50%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  transform: skewX(-20deg);
  animation: loading 1.5s infinite;
  box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.05);
}

.dark .animation {
  background-color: rgba(255, 255, 255, 0.2);
}

@keyframes loading {
  0% {
    transform: translateX(-250%);
  }

  50% {
    transform: translateX(-60%);
  }

  100% {
    transform: translateX(150%);
  }
}
